<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_register_available" size="lg"
           :on-hide="hideRegisterAvailableModal"
           :modal-header-title="$t('taxi.register available')">
      <template v-slot:container>
        <ValidationObserver ref="form_register_available" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(registerAvailableRequest)">
            <div class="modal-body">
              <AppRadio
                :radio-inputs="[
                  {label: $t('taxi.taxi available type 1'), value: 1},
                  {label:$t('taxi.taxi available type 2'), value: 2}
                 ]" name="TO_plan" :label="$t('taxi.taxi available type')"
                rules="required"
                v-model="entryToRegisterAvailable.type"></AppRadio>
              <AppDatePicker name="date" :label="$t('taxi.taxi available date')" rules="required"
                             :options="{startDate: new Date()}"
                             v-model="entryToRegisterAvailable.date"/>
              <AppSelect v-if="entryToRegisterAvailable.type == 1" :options="{placeholder: $t('common.taxi_type')}"
                         name="taxi_type"
                         :label="$t('common.taxi_type')" rules="required"
                         :options-data="entryToRegisterAvailable.taxi_types"
                         v-model="entryToRegisterAvailable.taxi_type_id">
              </AppSelect>
              <AppInput type="int" rules="required" name="cars_num" :label="$t('taxi.cars num')"
                        v-model="entryToRegisterAvailable.cars_num"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('taxi.create taxi')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <ValidationObserver ref="create_code" v-slot="{ passes, valid, validated }">
                <AppInput hidden name="create_code_force_reject" v-model="validate.create_code_force_reject"></AppInput>
                <AppInput name="code" cleave-regex="[^a-zA-Z]" :label="$t('taxi.code')" type="string-uppercase-length:2"
                          placeholder="アルファベット２文字以内"
                          rules="required|booking_code:@create_code_force_reject"
                          v-model="entryToSave.code"/>
              </ValidationObserver>
              <AppInput name="name" :label="$t('taxi.taxi name')" type="string-length:30" rules="required"
                        v-model="entryToSave.name"/>
              <AppInput name="address" :label="$t('common.address')" type="string" rules="required"
                        v-model="entryToSave.address"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToSave.phone"/>
              <AppInput name="fax" :label="$t('common.fax')" type="number-length:10" rules="fax"
                        v-model="entryToSave.fax"/>
              <AppInput name="kidchair_num_default" :label="$t('taxi.kidchair_num_default')" type="int" rules="required"
                        v-model="entryToSave.kidchair_num_default"/>
              <div class="app-form-group row form-group">
                <label class="col-md-4 input-label control-label p-0 p-r-5" style="margin-top: 0">
                  {{ $t('common.mail address') }} <span class="color-active">＊</span></label>
                <div class="col-md-8 form-group m-b-0 p-0">
                  <div class="">
                    <ValidationObserver ref="create_emails" v-slot="{ passes, valid, validated }">
                      <div class="m-t-5" v-for="(email, key) in entryToSave.emails">
                        <AppInput hidden :name="'create_emails_force_reject_' + key"
                                  v-model="validate.create_emails_force_reject[key]"></AppInput>
                        <AppInput col-input="12" :name="'email' + key" type="email"
                                  :rules="V_EMAIL_REQUIRED + '|email_unique:@create_emails_force_reject_' + key"
                                  boundary-class="b-b-0 p-0 m-0"
                                  v-model="entryToSave.emails[key]">
                          <template v-if="entryToSave.emails.length > 1" v-slot:append>
                            <div @click="entryToSave.emails.splice(key, 1)" class="btn-tool delete"></div>
                          </template>
                        </AppInput>
                      </div>
                    </ValidationObserver>
                    <button class="btn btn-create m-t-10" type="button" @click="entryToSave.emails.push('')">
                      メールを追加
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('taxi.update taxi')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <ValidationObserver ref="update_code" v-slot="{ passes, valid, validated }">
                <AppInput hidden name="update_code_force_reject"
                          v-model="validate.update_code_force_reject"></AppInput>
                <AppInput name="code" cleave-regex="[^a-zA-Z]" :label="$t('taxi.code')" type="string-uppercase-length:2"
                          placeholder="アルファベット２文字以内"
                          rules="required|booking_code:@update_code_force_reject"
                          v-model="entryToEdit.code"/>
              </ValidationObserver>
              <AppInput name="name" :label="$t('taxi.taxi name')" type="string-length:30" rules="required"
                        v-model="entryToEdit.name"/>
              <AppInput name="address" :label="$t('common.address')" type="string" rules="required"
                        v-model="entryToEdit.address"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToEdit.phone"/>
              <AppInput name="fax" :label="$t('common.fax')" type="number-length:10" rules="fax"
                        v-model="entryToEdit.fax"/>
              <AppInput name="kidchair_num_default" :label="$t('taxi.kidchair_num_default')" type="int" rules="required"
                        v-model="entryToEdit.kidchair_num_default"/>
              <div class="app-form-group row form-group">
                <label class="col-md-4 input-label control-label p-0 p-r-5" style="margin-top: 0">
                  {{ $t('common.mail address') }} <span class="color-active">＊</span></label>
                <div class="col-md-8 form-group m-b-0 p-0">
                  <div class="">
                    <ValidationObserver ref="update_emails" v-slot="{ passes, valid, validated }">
                      <div class="m-t-5" v-for="(email, key) in entryToEdit.emails">
                        <AppInput hidden :name="'update_emails_force_reject_' + key"
                                  v-model="validate.update_emails_force_reject[key]"></AppInput>
                        <AppInput col-input="12" :name="'email' + key" type="email"
                                  :rules="V_EMAIL_REQUIRED + '|email_unique:@update_emails_force_reject_' + key"
                                  boundary-class="b-b-0 p-0 m-0"
                                  v-model="entryToEdit.emails[key]">
                          <template v-if="entryToEdit.emails.length > 1" v-slot:append>
                            <div @click="entryToEdit.emails.splice(key, 1)" class="btn-tool delete"></div>
                          </template>
                        </AppInput>
                      </div>
                    </ValidationObserver>
                    <button class="btn btn-create m-t-10" type="button" @click="entryToEdit.emails.push('')">
                      メールを追加
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('taxi.list'),
      validate: {
        create_code_force_reject: false,
        update_code_force_reject: false,
        create_emails_force_reject: [],
        update_emails_force_reject: [],
      },
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.TAXI_LIST,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.TAXI_DELETE,
        }
      },
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        deleteEntry: true,
        others: [
          {
            label: this.$t('taxi.branch label'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.TAXI_BRANCH_LIST,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default">${this.$t('taxi.branch label')}</div>`,
          },
          {
            label: this.$t('taxi.available taxi'),
            click: entry => this.registerAvailableHandle(entry),
            contentHtml: entry => `<div class="btn-tool default">${this.$t('taxi.available taxi')}</div>`,
          },
          {
            label: this.$t('taxi.taxi type label'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.TAXI_TYPE_LIST,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default">${this.$t('taxi.taxi type label')}</div>`,
          },
          {
            label: this.$t('taxi.fee rule'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.TAXI_FEE_RULE,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default">${this.$t('taxi.fee rule')}</div>`,
          },
          {
            label: this.$t('taxi.tourist option'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.TOURIST_OPTION,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default">${this.$t('taxi.tourist option')}</div>`,
          },
        ]
      },
      tools: {
        search: {
          // reset: true,
          // searchAll: true,
          filters: []
        }
      },
      entrySelectedDelete: {},
      entryToRegisterAvailable: {},
      entryToSave: {
        emails: ['']
      },
      entryToEdit: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'code', label: this.$t('taxi.code'), detailHeader: true, class: 'min-w-80'},
        {name: 'name', label: this.$t('taxi.taxi name'), detailHeader: true},
        {name: 'address', label: this.$t('common.address'), sortable: false},
        {name: 'phone', label: this.$t('common.phone'), sortable: false},
        {
          name: 'emails',
          label: this.$t('common.mail address'),
          computed: entry => _.isArray(entry.emails) ? entry.emails.join(',') : '',
          sortable: false
        },
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  watch: {
    'entryToSave.code': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.validate.create_code_force_reject = !this.validateTaxiCode(this.entryToSave);
          setTimeout(() => this.$refs.create_code.validate(), 100)
        }
      },
      deep: true
    },
    'entryToEdit.code': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.validate.update_code_force_reject = !this.validateTaxiCode(this.entryToEdit);
          setTimeout(() => this.$refs.update_code.validate(), 100)
        }
      }
    },
    'entryToSave.emails': {
      handler: function (emails, oldEmails) {
        if (emails && emails.length) {
          this.validateTaxiEmail(this.entryToSave);
        }
      },
    },
    'entryToEdit.emails': {
      handler: function (emails, oldEmails) {
        if (emails && emails.length) {
          this.validateTaxiEmail(this.entryToEdit);
        }
      },
    },
    deep: true
  },
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {
        emails: ['']
      }
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {name, emails, phone, address, fax, code, kidchair_num_default} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.TAXI_SAVE, {
        name, emails, phone, address, fax, code, kidchair_num_default
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.cloneDeep(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {name, emails, phone, address, fax, code, kidchair_num_default} = this.entryToEdit
      const res = await this.$request.patch(this.$consts.API.ADMIN.TAXI_EDIT(this.entryToEdit.id), {
        name, emails, phone, address, fax, code, kidchair_num_default
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    /* UPDATE entry end */

    registerAvailableRequest() {
      const {type, date, taxi_type_id, cars_num, code, taxi_company_id} = this.entryToRegisterAvailable
      this.$request.post(this.$consts.API.ADMIN.TAXI_AVAILABLE_REGISTER, {
        type, date, taxi_type_id, cars_num, code, taxi_company_id
      }).then(res => {
        if (!res.hasErrors()) {
          this.$appNotice.success(this.$t('common.msg update ok'))
          this.$refs.modal_register_available.hide();
        } else if (res.data?.errors) {
          this.$refs.form_register_available.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      })
    },
    hideRegisterAvailableModal() {
      this.$refs.form_register_available.reset()
    },
    registerAvailableHandle(entry) {
      this.entryToRegisterAvailable = {
        type: 1,
        date: moment().format('YYYY/MM/DD'),
        taxi_types: entry.taxi_types,
        taxi_company_id: entry.id,
      }
      this.$refs.modal_register_available.show();
    },
    validateTaxiCode(entry) {
      var result = true;
      var taxiCompanies = this.$refs.table.entriesRes?.data?.relateData?.taxi_companies;
      if (!taxiCompanies) {
        taxiCompanies = [];
      }
      _.forEach(taxiCompanies, taxi => {
        if (entry.id) {
          if (taxi.id != entry.id && entry.code == taxi.code) {
            result = false;
            return false;
          }
        } else {
          if (entry.code == taxi.code) {
            result = false;
            return false;
          }
        }
      })
      return result;
    },
    validateTaxiEmail(entry) {
      var taxiCompanies = this.$refs.table.entriesRes?.data?.relateData?.taxi_companies;
      if (!taxiCompanies) {
        return;
      }
      _.forEach(entry.emails, (email, index) => {
        if (!email || !email.length) {
          return;
        }
        var result = true;
        if (_.countBy(entry.emails, emailCheck => email.toLowerCase() === emailCheck.toLowerCase()).true > 1) {
          result = false;
        } else {
          _.forEach(taxiCompanies, taxi => {
            var taxiEmails = taxi.emails.map(email => email.toLowerCase())
            if (entry.id) {
              if (taxi.id != entry.id && taxiEmails.includes(email.toLowerCase())) {
                result = false;
                return false;
              }
            } else {
              if (taxiEmails.includes(email.toLowerCase())) {
                result = false;
                return false;
              }
            }
          })
        }
        if (entry.id) {
          this.validate.update_emails_force_reject[index] = !result;
          setTimeout(() => this.$refs.update_emails.validate(), 100)
        } else {
          this.validate.create_emails_force_reject[index] = !result;
          setTimeout(() => this.$refs.create_emails.validate(), 100)
        }
      })
    }
  }
}
</script>
